import { createContext, useState, useEffect } from 'react'
import { userService } from '../../../services/Public'

export const ContentContext = createContext()

export const ContentProvider = ({children}) => {

    const    nameController = 'planes',
    [allData, setAllData] = useState([]),
    All = async () => {
        
        const response = await userService.GetRoute(`${nameController}/all-planes`)
        setAllData((response.length) ? JSON.parse(response[0]['jsoN_F52E2B61-18A1-11d1-B105-00805F49916B']).Planes : [])
    },
    value = {
        allData
    }

    useEffect(
        () => {
            All()
        }, []
    )
    return (
        <ContentContext.Provider value={value} >
            {children}
        </ContentContext.Provider>
    )
}
