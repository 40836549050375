/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const IT = () => {
  return (
    <AsideMenuItemWithSub
      to='/crafted/pages'
      title='Reportes'
      fontIcon='bi-archive'
      icon='/media/icons/duotune/abstract/abs027.svg'
    >
      <AsideMenuItem
        to='/reportes/vendedores'
        icon='/media/icons/duotune/general/gen049.svg'
        title='Vendedores'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/reportes/consolidado-vendedor'
        icon='/media/icons/duotune/general/gen049.svg'
        title='Consolidado Vendedor'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/reportes/reporte-ventas'
        icon='/media/icons/duotune/general/gen049.svg'
        title='Reporte de ventas'
        fontIcon='bi-layers'
      />
        <AsideMenuItem
        to='/reportes/reporte-productos'
        icon='/media/icons/duotune/general/gen049.svg'
        title='Reporte de ventas'
        fontIcon='bi-layers'
      />
    </AsideMenuItemWithSub>
  )
}

export default IT
