/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const IT = () => {

  return (
    <AsideMenuItemWithSub
    to='/crafted/pages'
    title='Cuentas por Cobrar'
    fontIcon='bi-wallet'
    icon='/media/icons/duotune/finance/fin005.svg'
  >
    <AsideMenuItem
      to='/cxc/cliente'
      icon='/media/icons/duotune/communication/com005.svg'
      title='Clientes'
      fontIcon='bi-layers'
    />
    <AsideMenuItem
      to='/cxc/consulta-cliente'
      icon='/media/icons/duotune/general/gen004.svg'
      title='Consultar Cliente'
    />
    <AsideMenuItem
      to='/cxc/creditos'
      icon='/media/icons/duotune/finance/fin008.svg'
      title='Creditos'
      fontIcon='bi-layers'
    />
  </AsideMenuItemWithSub>
  )
}

export default IT