/* eslint-disable react/jsx-no-target-blank */
import {Fragment} from 'react'
import {AsideMenuItem} from '../../AsideMenuItem'

const IT = () => {
  return (
    <Fragment>
      <AsideMenuItem
        to='/operador/facturacion'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
        title='Facturación'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/operador/historial/facturacion'
        icon='/media/icons/duotune/files/fil001.svg'
        title='Historial Facturación'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/operador/envio'
        icon='/media/icons/duotune/general/gen016.svg'
        title='Envío'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/operador/historial/envio'
        icon='/media/icons/duotune/files/fil001.svg'
        title='Historial Envío'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/operador/cotizaciones'
        icon='/media/icons/duotune/general/gen005.svg'
        title='Cotizaciones'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/operador/historial/cotizaciones'
        icon='/media/icons/duotune/files/fil001.svg'
        title='Historial Cotizaciones'
        fontIcon='bi-layers'
      />
    </Fragment>
  )
}

export default IT
