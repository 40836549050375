import { useContext } from 'react'
import { Modal, Spinner } from 'react-bootstrap-v5'
import { LoadingContext } from './provider'
import { UserContext } from '../context/userContext'

export const Loading=()=>{
    
    const {showLoad} =useContext(LoadingContext),
          { userAuth } = useContext(UserContext)
    return(
        <Modal show={showLoad} className="card card-custom card-flush background-dark" backdrop="static" centered keyboard={false}>
            <div className="card card-custom card-flush">
                <div className="card-header ribbon ribbon-start ribbon-clip min-h">
                    <div className="ribbon-label">
                        {userAuth && userAuth.emp}
                        <span className="ribbon-inner bg-dark"></span>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="card-title w-100 text-center h3 text-dark"><span className="me-10">Su petición esta siendo procesada, espere un momento por favor...</span>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Loading