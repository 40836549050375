/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const IT = () => {
  return (
    <AsideMenuItemWithSub
      to='/crafted/pages'
      title='Admin'
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >
      <AsideMenuItem
        to='/admin/planes'
        icon='/media/icons/duotune/general/gen026.svg'
        title='Planes'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/tipo-persona'
        icon='/media/icons/duotune/general/gen049.svg'
        title='Tipo Persona'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/personas'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Personas'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/empresas'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
        title='Empresas'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/roles'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Roles'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/usuario'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Usuarios'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/admin/configuracion/fel'
        icon='/media/icons/duotune/files/fil003.svg'
        title='Config FEL'
        fontIcon='bi-layers'
      />
    </AsideMenuItemWithSub>
  )
}

export default IT
