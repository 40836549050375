import { RouteBase } from './BaseUrl'


export async function GetRoute(url){

    const response = await fetch(`${RouteBase}/${url}`,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
            }
        }
    )
    .then(data => data.json())
    .catch(() => [])
    return await response;
}


// **************************************************************************
// Funcion guardar registros json
// **************************************************************************
export async function PostRoute(url,form){
    const data = JSON.stringify
        (
            {
                usuario : JSON.parse(localStorage.getItem('authUser')).id,
                ...form
            }
        );    
    // elviamos el formulario con fetch por el metodo post
    const response = await fetch(`${RouteBase}/${url}`,
        {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
            },
            body: data
        }
    )
    .then(data => data.json())
    .catch(() => [])

    return await response;
}


// **************************
// Funcion guardar registros con documentos
// **************************
export async function PostRouteFD(url,form){
        
    form.append('usuario',JSON.parse(localStorage.getItem('authUser')).id);
    // elviamos el formulario con fetch por el metodo post
   const response = await fetch(`${RouteBase}/${url}`,
       {
           method: 'POST',
           mode: 'cors',
           headers: {
               'Access-Control-Allow-Origin' : '*',
               'Authorization': `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`,
               
           },
           body: form
       }
   ).then(function(data) {
    // StatusCode(data);
    return  data.json()
    }).catch(function(data) {
    // StatusCode(data);
        return [];
    });

    return await response;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    GetRoute,
    PostRoute,
    PostRouteFD
}
