import {FC, useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {AsideMenu} from './AsideMenu'

const AsideDefault: FC = () => {
  const dataEmpresa = useSelector((state: any) => state.stateEmpresa.stateEmpresa)
  const [minimized, setMinimized] = useState(false)
  const {config, classes} = useLayout()
  const {aside} = config

  useEffect(() => {
    const element = document.getElementById('kt_aside') as HTMLDivElement | null

    const resizeObserver = new ResizeObserver((e) => {
      e[0].contentRect.width !== 265 ? setMinimized(true) : setMinimized(false)
    })

    if (element) {
      resizeObserver.observe(element)
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element)
      }
    }
  }, [])

  const renderLogo = (theme: string) => (
    <Link to='/dashboard'>
      <img alt='Logo' className='h-25px' src={`data:image/png;base64,${dataEmpresa.logo}`} />
      {theme === 'dark' && dataEmpresa?.length !== 0 && !minimized && (
        <span
          className={`ms-6 fw-bolder ${theme === 'dark' ? 'text-white' : ''} ${
            dataEmpresa?.nombreComercial?.length <= 10 ? 'h4' : 'h6'
          }`}
        >
          {dataEmpresa?.nombreComercial}
        </span>
      )}
      {theme === 'light' && <span className='h-25px logo'>logo</span>}
    </Link>
  )

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {aside.theme === 'dark' && renderLogo('dark')}
        {aside.theme === 'light' && renderLogo('light')}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr080.svg'
              className='svg-icon-1 rotate-180'
            />
          </div>
        )}
      </div>

      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-custom btn-primary w-100'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL}
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
          title='Check out the complete documentation with over 100 components'
        >
          <span className='btn-label'>Manual</span>
          <span className='svg-icon btn-icon svg-icon-2'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' />
          </span>
        </a>
      </div>
    </div>
  )
}

export {AsideDefault}
