import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Vendedor = lazy(() => import('../../../views/reportes/vendedor/index'))
const ConsolidadoVendor = lazy(() => import('../../../views/reportes/consolidadoVendedor/index'))
const ReporteVentas = lazy(() => import('../../../views/reportes/clientesFrecuentes/index'))
const ReporteProductos = lazy(() => import('../../../views/reportes/clientesFrecuentes/index'))

const Index = (props: any) =>{
  return (
    <Switch>
        <Route path={`${props.match.path}/vendedores`} component={Vendedor} />
        <Route path={`${props.match.path}/consolidado-vendedor`} component={ConsolidadoVendor} />
        <Route path={`${props.match.path}/reporte-ventas`} component={ReporteVentas} />
        <Route path={`${props.match.path}/reporte-productos`} component={ReporteProductos} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
