import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const   Soporte = lazy(() => import('../../../views/soporte/ticket/index')),
        SoporteOne = lazy(() => import('../../../views/soporte/soporteOne/index'))

const Index = (props: any) =>{
  return (
    <Switch>
        <Route path={`${props.match.path}/:noTicket`} component={SoporteOne} />
        <Route path={`${props.match.path}`} component={Soporte} />        
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
