
import { Fragment, useState, useEffect } from 'react'
import { Row, Col ,Modal,Form,InputGroup,FormControl,Alert,Button,Image} from 'react-bootstrap-v5'
import { useForm } from "react-hook-form";
import * as Icon from 'react-feather'
import {PostRoute} from '../../services/Private'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {ToastContainer, toast, Bounce } from 'react-toastify'

const Index = () =>{
        const [allData, setAllData] = useState<any>([]),
        Mensaje = `
        La contraseña debe de tener ocho caracteres como mínimo, debe incluir
        letras mayúsculas,
        letras minúsculas,
        números y
        cualquiera de los siguientes caracteres: ( $, @, !, %, *, ?, &, _ )`,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [allDataRoles, setAllDataRoles] = useState<any>([]),
{ register, handleSubmit, formState: { errors }  } = useForm(),
        [Visualizar,setVisualizar] =useState<any>('password'),
        // Obtiene varios registros
        All=async()=>{
            const response = await PostRoute('usuario/perfil')
            setAllData((response.length > 0 )?response[0]:[]);
        },
        AllRoles=async(id:any)=>{
          if(id.i>0 && id.i!==null && id.i!==undefined  ){
          const response = await PostRoute('usuario/list-roles',{i:id.i})
          setAllDataRoles((response.length > 0 )?response:[]);
          
        }
      },
        logout = () => {
            localStorage.removeItem('authUser');
            // history.push('/auth');
            window.location.href = "/auth";
          },
        onSubmit= async(data : any)=>{

          if(data.passwordTres===data.passwordDos){
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const response = await PostRoute(`usuario/change/password`, {
            id: allData.id,
            password0: data.passwordUno,
            password: data.passwordTres
          } );
          
          toast.success("La contraseña se actualizo correctamente", { position: 'bottom-right', transition: Bounce });
          logout()
        }else{
            toast.error("La contraseña no coincide", { position: 'bottom-right', transition: Bounce });
          }
        }
        useEffect(
          () => {
              const functionFetch=async()=>{
                  All()
                  //AllRoles()
              }
              functionFetch();
          // eslint-disable-next-line react-hooks/exhaustive-deps
          },[]
        )
        const roles:any=localStorage.getItem('authUser'),
        user = JSON.parse(roles)
        useEffect(
          () => {
              const functionFetch=async()=>{
                  if(user!==null){AllRoles(user)}
              }
              functionFetch();
          // eslint-disable-next-line react-hooks/exhaustive-deps
          },[]
        )

    return(
        <Fragment>
                      <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          show={true} >
    <Form onSubmit={handleSubmit(onSubmit)}>
    <Modal.Header >
      <Modal.Title>ACTUALIZAR CONTRASEÑA</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Col>
        <Image src={toAbsoluteUrl('/media/png/update-password.png')} width={300} height={300}/>
        </Col>
        <Col>
        <Alert variant="warning">
                            <small>
                                {Mensaje}
                            </small>
                            </Alert>
        <Form.Label column >Contraseña Anterior</Form.Label>
                <InputGroup className="mb-3">

                  <FormControl

                    type={Visualizar}
                    {...register('passwordUno',
                    {
                        required: "Este campo es requerido",
                    }
                )
            }
            isInvalid={!!errors.passwordUno}
                  />

                  <Button onClick={(Visualizar==='password')?()=>setVisualizar('text'):()=>setVisualizar('password')} variant="outline-primary" >
                <Icon.Eye size={16}/>
                  </Button>

                </InputGroup>
                <p className="text-danger fw-danger">{!!errors.passwordUno && errors.passwordUno.message}</p>
                <Form.Label column >Nueva Contraseña</Form.Label>
              <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">

                <Col >
                  <Form.Control
                  type={Visualizar}
                  {...register('passwordDos',
                    {
                        required: "Este campo es requerido",
                        pattern: 
                          {
                              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*?&])[A-Za-z\d$@$!_%*?&]{8,15}[^'\s]/,
                              message: "La contraseña no cumple con los requisitos"
                          },
                      minLength: 
                          {
                              value: 8, 
                              message: 'Mínimo 8 carácteres!'
                          }
                    }
                )
            }
            isInvalid={!!errors.passwordDos}/>

                </Col><p className="text-danger fw-danger">{!!errors.passwordDos && errors.passwordDos.message}</p>
                </Form.Group>

              <Form.Label column>Repetir contraseña</Form.Label>

              <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                 <Col >


                  <Form.Control
                  type={Visualizar}
                  {...register('passwordTres',
                    {
                        required: "Este campo es requerido",
                        pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*?&])[A-Za-z\d$@$!_%*?&]{8,15}[^'\s]/,
                            message: "La contraseña no cumple con los requisitos"
                        },
                    minLength: 
                        {
                            value: 8, 
                            message: 'Mínimo 8 carácteres!'
                        }
                    }
                )
            }
            isInvalid={!!errors.passwordTres}/>
            <p className="text-danger fw-danger">{!!errors.passwordTres && errors.passwordTres.message}
            </p></Col>
              </Form.Group>
        </Col>

      </Row>
         


</Modal.Body>
    <Modal.Footer>
      <Button  variant="primary" type="submit">Actualizar</Button>
    </Modal.Footer>
    </Form>
  </Modal>
  <ToastContainer/>
        </Fragment>
        )
}

export default Index