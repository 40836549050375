/* eslint-disable react/jsx-no-target-blank */
import {useContext, Fragment} from 'react'
import {AsideMenuItem} from './AsideMenuItem'
import Admin from './Menu/Admin/index'
import Administracion from './Menu/Administracion/index'
import Operador from './Menu/Operador/index'
import Reportes from './Menu/reportes/index'
import Cxc from './Menu/cxc/index'
import {UserContext} from '../../../../app/utility/context/userContext'

export function AsideMenuMain() {
  let {userAuth} = useContext(UserContext),
    Roles = userAuth.roles && JSON.parse(userAuth.roles)

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Tablero'
        fontIcon='bi-app-indicator'
      />
      {Roles && (
        <Fragment>
          {Roles.includes('3F25A') && <Admin />}
          {Roles.includes('D9C7B') && <Administracion />}
          {Roles.includes('D9C7B') && <Cxc />}
          {Roles.includes('D9C7B') && <Reportes />}
          {Roles.includes('B3C4F') && <Operador />}
          {Roles.includes('3F25A') && (
            <AsideMenuItem
              to='/admin/historial-soporte'
              icon='/media/icons/duotune/files/fil001.svg'
              title='Historial Soporte'
              fontIcon='bi-layers'
            />
          )}

          <AsideMenuItem
            to='/soporte'
            icon='/media/icons/duotune/coding/cod009.svg'
            title='Soporte'
            fontIcon='bi-layers'
          />
        </Fragment>
      )}
    </>
  )
}
