import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Facturacion = lazy(() => import('../../../views/operador/facturacion/index'))
const HistorialFactura = lazy(
  () => import('../../../views/administrador/historialFacturacion/index')
)
const Envio = lazy(() => import('../../../views/operador/envio/index'))
const HistorialEnvio = lazy(() => import('../../../views/administrador/historialEnvio/index'))

const Cotizacion = lazy(() => import('../../../views/operador/cotizaciones/index'))
const HistorialCotizaciones = lazy(() => import('../../../views/administrador/historialCotizaciones/index'))

const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/facturacion`} component={Facturacion} />
      <Route path={`${props.match.path}/historial/facturacion`} component={HistorialFactura} />
      <Route path={`${props.match.path}/envio`} component={Envio} />
      <Route path={`${props.match.path}/historial/envio`} component={HistorialEnvio} />
      <Route path={`${props.match.path}/cotizaciones`} component={Cotizacion} />
      <Route path={`${props.match.path}/historial/cotizaciones`} component={HistorialCotizaciones} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
