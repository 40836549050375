import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const   Producto = lazy(() => import('../../../views/administrador/productos/index'))
const Config = lazy(() => import('../../../views/administrador/config/index'))
const Vendedor = lazy(() => import('../../../views/administrador/vendedor/index'))

const Index = (props: any) =>{
  return (
    <Switch>
        <Route path={`${props.match.path}/config`} component={Config} />
        <Route path={`${props.match.path}/producto`} component={Producto} />
        <Route path={`${props.match.path}/vendedor`} component={Vendedor} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
