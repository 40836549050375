import { useState } from  'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { Form, Alert, Button } from 'react-bootstrap-v5'
import { userService } from "../../services/UserServices"
import {toAbsoluteUrl} from '../../../_metronic/helpers'

type SendLogin = {
    username: string;
    password: string;
  };

const Login=()=>{
    const { register, handleSubmit, formState: { errors } } = useForm(),
    [messageError, setMessageError] = useState<String>(''),
    [validInput, setValidInput] = useState<boolean>(false),
    [loading, setLoading] = useState<boolean>(false),
    onSubmit = async (data: SendLogin)=>{
        setMessageError('');
        setValidInput(true);
        setLoading(true);
        userService.login(data).then(
            () => 
            {
                window.location.href = "/"
            },
            error => 
            {
                setValidInput(false);
                setLoading(false);
                if(String(error) === 'TypeError: Failed to fetch'){
                    setMessageError('El navegador no pudo establecer una conexión al servidor');
                }else{
                    setMessageError( error);
                }      
            }
        );
    } 

    
     
    return(
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
               
            <div className="text-center mb-10 mb-lg-10">
            <img
                    alt="Logo"
                    className="max-h-75px"
                    width="40%"
                    src={toAbsoluteUrl("/media/logos/facturacionB.svg")}
                />
                <h3 className="h1 text-uppercase">
                    Inicio de Sesión
                </h3>
                {/* <p className="text-muted fw-bolder">
                    
                </p> */}
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={handleSubmit((data : any ) => onSubmit(data))}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                <div className="alert alert-dismissible bg-light-dark d-flex flex-column flex-sm-row p-5 mb-10">
                    <div className="d-flex flex-column text-primary pe-0 pe-sm-10 fw-bolder">
                        Ingrese nombre de usuario y contraseña para Iniciar Sesión
                    </div>

                    <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                        <span className="svg-icon svg-icon-1 svg-icon-primary"></span>
                    </button>
                    </div>

                <div className="form-group fv-plugins-icon-container mb-10">
                    <input
                        placeholder='Usuario'
                        type="text"
                        className={
                            clsx(
                                'form-control form-control-solid h-auto py-5 px-5',
                                {
                                    'is-valid':validInput
                                },
                                {
                                    'is-invalid': errors.username && errors.username.type === "required"
                                }
                            )
                        }
                        {...register('username',
                                {   
                                    required: "Este campo es requerido",    
                                }
                            )
                        }
                    />                
                    {errors.username && errors.username.type === "required" && (
                        <div className="fv-plugins-message-container text-danger text-center">
                            <div className="fv-help-block">
                                {errors.username.message}
                            </div>
                        </div>
                        )}
                </div>
                <div className="form-group fv-plugins-icon-container mb-5">
                    <input
                        placeholder='Contraseña'
                        type="password"
                        className={
                            clsx(
                                'form-control form-control-solid h-auto py-5 px-5',
                                {
                                    'is-valid':validInput
                                },
                                {
                                    'is-invalid': errors.password && errors.password.type === "required" 
                                }
                            )
                        }
                        {...register("password", 
                                { 
                                    required: "Este campo es requerido",
                                }
                            )
                        }
                    />  
                    {errors.password && errors.password.type === "required" && (
                        <div className="fv-plugins-message-container text-danger text-center">
                            <div className="fv-help-block">
                                {errors.password.message}
                            </div>
                        </div>
                        )}
                </div>
                
                    {   messageError && 
                        <Form.Group>
                            <Alert variant="alert-dismissible bg-light-danger text-danger fw-bolder text-center">
                                {messageError}
                            </Alert>
                        </Form.Group>
                    }
                        
                
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <Link
                    to="/auth/forgot-password"
                    className="text-primary text-hover-dark my-3 mr-2"
                    id="kt_login_forgot"
                >
                   ¿Has olvidado tu contraseña?
                </Link>
                <Button
                    id="kt_login_signin_submit"
                    type="submit"
                    // disabled={formik.isSubmitting}
                    className={`btn btn-dark btn-sm px-5 py-4 my-3`}
                >
                    <span className="fw-bolder">Ingresar</span>
                    {loading && 
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Procesando...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        }
                </Button>
                </div>
            </form>
            </div>
    )
}

export default Login