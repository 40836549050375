import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const TipoPersona = lazy(() => import('../../../views/admin/TipoPersona/index')),
      Roles = lazy(() => import('../../../views/admin/Rol/index')),
      Planes = lazy(() => import('../../../views/admin/Planes/index')),
      Personas = lazy(() => import('../../../views/admin/Personas/index')),
      Empresas = lazy(() => import('../../../views/admin/Empresa/index')),
      usuarios = lazy(() => import('../../../views/admin/Usuario/index')),
      ConfigFEL = lazy(() => import('../../../views/admin/ConfiguracionFEL/index')),
      HistorialSoporte = lazy(() => import('../../../views/soporte/historialSoporte/index'))

const Index = (props: any) =>{
  return (
    <Switch>      
      <Route path={`${props.match.path}/roles`} component={Roles} />
      <Route path={`${props.match.path}/tipo-persona`} component={TipoPersona} />
      <Route path={`${props.match.path}/planes`} component={Planes} />
      <Route path={`${props.match.path}/personas`} component={Personas} />
      <Route path={`${props.match.path}/empresas`} component={Empresas} />
      <Route path={`${props.match.path}/usuario`} component={usuarios} />
      <Route path={`${props.match.path}/configuracion/fel`} component={ConfigFEL} />
      <Route path={`${props.match.path}/historial-soporte`} component={HistorialSoporte} />

      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
