import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Clientes = lazy(() => import('../../../views/cxc/clientes/index'))
const ConsultaCliente = lazy(() => import('../../../views/cxc/consultaClientes/index'))
const Creditos = lazy(() => import('../../../views/cxc/creditos/index'))
const Cliente = lazy(() => import('../../../views/cxc/facturasCliente/index'))

const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/cliente`} component={Clientes} />
      <Route path={`${props.match.path}/consulta-cliente`} component={ConsultaCliente} />
      <Route path={`${props.match.path}/creditos/:nit/:id`} component={Cliente} />
      <Route path={`${props.match.path}/creditos`} component={Creditos} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
