import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'

type Props = {
  className?: string
  path: string
  svgClassName?: string
  onClick?: (event: any) => void
}

const KTSVG: React.FC<Props> = ({className = '', path, svgClassName = 'mh-50px', onClick}) => {
  return (
    <span className={`svg-icon ${className}`} onClick={onClick}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  )
}

export {KTSVG}

