import { useState, useMemo, useContext } from 'react'
import { Row, Col } from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
import CardPlan from './cardPlan'
import { ContentContext } from './context'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Pagination from '../../../utility/pagination/Pagination'

let PageSize = 9;

const List = () => {
    const { allData } = useContext(ContentContext),
    [currentPage, setCurrentPage] = useState(1)
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return (allData).slice(firstPageIndex, lastPageIndex);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentPage, allData])
    return (
            <div className="post d-flex flex-column-fluid align-items-center" id="kt_post">
                <div id="kt_content_container" className="container-xxl">
                    <div className="card" id="kt_pricing">
                        <div className="card-body p-lg-17 ">
                            <div className="d-flex flex-column">
                                <div className="text-center">
                                    <Link to="/auth">
                                        <img
                                            alt="Logo"
                                            width="70px"
                                            src={toAbsoluteUrl("/media/logos/facturacionB.png")}
                                        />
                                    </Link>
                                </div>
                                
                                <div className="mb-5 text-center">
                                    <h1 className="fs-1hx fw-bolder">BELAINVOICE</h1>
                                    <div className="text-gray-400 fw-bold fs-5">Sistema de Facturacion Electronica
                                    <br/>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className="link-primary fw-bolder text-uppercase fs-2hx">Elige tu plan</a>.
                                    </div>
                                </div>

                                <div className="nav-group nav-group-outline mx-auto mb-15" data-kt-buttons="true">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active" data-kt-plan="month">Monthly</a>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3" data-kt-plan="annual">Annual</a>
                                </div>
                                
                                <div className="row g-10">
                                    { 
                                        currentTableData.map(
                                            (item, index) => {
                                                return (
                                                    <CardPlan
                                                        key={index}
                                                        item={item}
                                                    />
                                                )
                                            }
                                        )            
                                    }
                                </div>
                                <Row>
                                    <Col className="pt-5">
                                        <Pagination
                                            className="pagination-bar justify-content-center"
                                            currentPage={currentPage}
                                            totalCount={allData.length}
                                            pageSize={PageSize}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default List