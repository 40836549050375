import { Link, useHistory } from 'react-router-dom'
import { useState } from 'react'
import { Form, Alert} from "react-bootstrap-v5"
import { PostRoute } from '../../services/Public'
import clsx from 'clsx'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { useForm } from "react-hook-form";

const ForgotPassword = (props : any) => {
    const history = useHistory(),
        { register, handleSubmit, formState: { errors } } = useForm(),
            // eslint-disable-next-line 
        [validInput, setValidInput] = useState<boolean>(false),
        [loading, setLoading] = useState<boolean>(false),

        onSubmit = (data: any) => {
            setLoading(true);
            let jsData = {
                email: data.email
            }
            submitForm(jsData)
        },

        submitForm = async (data: any) => {
            const response = await PostRoute('usuario/forgot/password', data);
            if (response && response.value > 0) {
                var msg = response.message.toString();
                setLoading(false);
                toast.success(msg, { position: 'bottom-right', transition: Bounce, onClose: ()=> history.push('/auth/login') });
            }
        }
    let alert
        try {
            alert = props.location.state.alert
        } catch (e) { }

    return (
        <div className="login-form login-forgot" style={{ display: "block" }}>
            { alert &&
                <div className="text-center mb-20 mb-lg-20">
                    <Alert variant="danger" style={{ width: "32rem" }}>
                        <small>
                            {alert}
                        </small>
                    </Alert>
                </div>
            }
            <div className="text-center mb-10 mb-lg-10">
                <h3 className="h1">¿Has olvidado tu contraseña?</h3>
                <div className="text-muted fw-bold">
                    Ingrese su correo electrónico para restablecer su contraseña
                </div>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
                <div className="form-group fv-plugins-icon-container">
                    <input
                        type="email"
                        placeholder="ejemplo@gmail.com"
                        className={
                            clsx(
                                'form-control form-control-solid h-auto py-5 px-5',
                                {
                                    'is-valid': validInput
                                },
                                {
                                    'is-invalid': errors.email && errors.email.type === "required"
                                }
                            )
                        }
                        {
                            ...register('email',
                                {
                                    required: "Este campo es requerido",
                                }
                            )
                        }
                    />
                    <p className="text-danger fw-danger">{!!errors.email && errors.email.message}</p>
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        id="kt_login_forgot_submit"
                        type="submit"
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    // disabled={formik.isSubmitting}
                    >
                        Enviar
                        {loading && 
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Procesando...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        }
                    </button>
                    <Link to="/auth">
                        <button
                            type="button"
                            id="kt_login_forgot_cancel"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                            Volver
                        </button>
                    </Link>
                </div>
            </Form>
            <ToastContainer />
        </div>
    )
}

export default ForgotPassword