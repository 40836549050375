/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

 import { FC, useState, Fragment } from 'react'
 import { Redirect, Switch, Route } from 'react-router-dom'
 import { MasterLayout } from '../../_metronic/layout/MasterLayout'
 import { PrivateRoutes } from './PrivateRoutes'
 import { Logout } from '../modules/auth'
 import AuthPage from '../views/auth/index'
 import Planes from '../views/public/planes/index'
 import { ErrorsPage } from '../views/errors/ErrorsPage'
 import { history } from "../services/history"
 import LoginFirst from "../views/Perfil/LoginFirst"
 
 const Routes: FC = () => {
   const isAuthorized: any = localStorage.getItem('authUser'),
     [logeado, setLogeado] = useState<boolean>(false),
     user = isAuthorized ? JSON.parse(isAuthorized) : null,
     publicR = [
      "/auth/login",
      "/auth/forgot-password",      
      "/auth/reset-password/",
      "/planes"
    ]
 
   history.listen((location, action) => {
     if (location.pathname === '/') { setLogeado(true); }
     if (publicR.includes(location.pathname)) { setLogeado(false); }
 
   })
   return (
     <Switch>
       {!logeado && !user ? (
         /*Render auth page when user at `/auth` and not authorized.*/
        <Fragment>
          <Route path='/auth' component={AuthPage} />
          <Route path='/planes' component={Planes} />
        </Fragment>
       ) : (
         /*Otherwise redirect to root page (`/`)*/
         <Redirect from='/auth' to='/' />
       )}
 
       <Route path='/error' component={ErrorsPage} />
       <Route path='/logout' component={Logout} />
 
       {!logeado && !user ? (
         /*Redirect to `/auth` when user is not authorized*/
         <Redirect to='/auth/login' />
       ) : (
         <>
           
           {// eslint-disable-next-line eqeqeq
           user && user.estado === 3 ? <LoginFirst />
             :
             <MasterLayout>
               <PrivateRoutes/>
             </MasterLayout>}
         </>
       )}
     </Switch>
   )
 }
 
 export { Routes }
 