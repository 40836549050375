import {Suspense, lazy, useContext} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import Admin from './routes/admin/index'
import Administracion from './routes/administrador/index'
import Operador from './routes/operador/index'
import Soporte from './routes/soporte/index'
import Cxc from './routes/cxc/index'
import Reportes from './routes/reportes/index'
        
import { UserContext } from '../../app/utility/context/userContext'

export function PrivateRoutes() {
  let { roles } = useContext(UserContext)

  const Dashboard = lazy(() => import('../views/index'))
  const Perfil = lazy(() => import('../views/Perfil/index'))
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/usuario/perfil' component={Perfil} />
        {
          roles.includes('3F25A') && <Route path='/admin' component={Admin} />
        }
        {
          roles.includes('D9C7B') && <Route path='/administracion' component={Administracion} />
        }
         {
          roles.includes('D9C7B') && <Route path='/reportes' component={Reportes} />
        }
        {
          roles.includes('D9C7B') && <Route path='/cxc' component={Cxc} />
        }   
        {
          roles.includes('B3C4F') && <Route path='/operador' component={Operador} />
        }
        
        <Route path='/soporte' component={Soporte} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        {/* <Redirect to='/error/404' /> */}
      </Switch>
    </Suspense>
  )
}
