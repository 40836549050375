/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const IT = () => {

  return (
    <AsideMenuItemWithSub
    to='/crafted/pages'
    title='Administración'
    fontIcon='bi-archive'
    icon='/media/icons/duotune/general/gen022.svg'
  >
    <AsideMenuItem
      to='/administracion/producto'
      icon='/media/icons/duotune/ecommerce/ecm009.svg'
      title='Productos'
      fontIcon='bi-layers'
    />
    <AsideMenuItem
      to='/administracion/config'
      icon='/media/icons/duotune/coding/cod001.svg'
      title='Configuración'
      fontIcon='bi-layers'
    />
    <AsideMenuItem
      to='/administracion/vendedor'
      icon='/media/icons/duotune/general/gen049.svg'
      title='Vendedores'
    />
  </AsideMenuItemWithSub>
  )
}

export default IT