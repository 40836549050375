import { Link, useLocation, useHistory} from 'react-router-dom'
import { useState, useMemo, useEffect } from 'react'
import { Form, Alert } from "react-bootstrap-v5"
import * as Icon from 'react-feather'
import { PostRoute } from '../../services/Public'
import clsx from 'clsx'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { useForm } from "react-hook-form";

const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
    
    let query = useQuery();
    const history = useHistory(),        
        token = query.get("token"),
        Mensaje = `
        La contraseña debe de tener ocho caracteres como mínimo, debe incluir
        letras mayúsculas,
        letras minúsculas,
        números y
        cualquiera de los siguientes caracteres: ( $, @, !, %, *, ?, &, _ )`,
        { register, handleSubmit, formState: { errors }, reset } = useForm(),
            // eslint-disable-next-line 
        [validInput, setValidInput] = useState<boolean>(false),
        [Visualizar,setVisualizar] =useState<any>('password'),
        [loading, setLoading] = useState<boolean>(false),

        onSubmit = async (data: any) => {
            setLoading(true);
            let jsData = {
                token: token,
                password: data.passwordUno
            }
            if(data.passwordUno===data.passwordDos){
                const response = await PostRoute('usuario/change/password/token', jsData);
                if (response && response.value > 0) {
                    var msg = response.message.toString();
                    setLoading(false);
                    toast.success(msg, { position: 'bottom-right', transition: Bounce, onClose: ()=> history.push('/auth/login')});
                    reset();
                }
            }else{
                toast.error('Contraseña no coincide', { position: 'bottom-right', transition: Bounce });
            }
        },
        verificarToken = async () => {
            if(token){
                const response = await PostRoute('usuario/token/password/check', { token: token });
                if (response.value > 10500 ) {
                    history.push({
                        pathname: '/auth/forgot-password',
                        state: { alert: 'El token para reseteo de contraseña ha expirado' }
                    })//redirigir 
                }
            }else{
                history.push({
                    pathname: '/auth/forgot-password',
                    state: { alert: 'El token para reseteo de contraseña ha expirado' }
                })//redirigir
            }
            
        }

        useEffect(() => {
            verificarToken();
            // eslint-disable-next-line react-hooks/exhaustive-deps
         }, []) 

    return (
        <div className="login-form login-forgot" style={{ display: "block" }}>
            <div className="text-center mb-10 mb-lg-10">
                <h3 className="h1">Cambia tu contraseña</h3>
                <div className="text-muted fw-bold">
                    <Alert variant="warning" style={{ width: "32rem" }}>
                        <small>
                            {Mensaje}
                        </small>
                    </Alert>
                </div>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
                <div className="form-group fv-plugins-icon-container">
                    <div className="input-group">                    
                        <input
                            type={Visualizar}
                            placeholder="Nueva contraseña"
                            className={
                                clsx(
                                    'form-control form-control-solid h-auto py-5 px-5',
                                    {
                                        'is-valid': validInput
                                    },
                                    {
                                        'is-invalid': errors.passwordUno && errors.passwordUno.type === "required"
                                    }
                                )
                            }
                            {
                                ...register('passwordUno',
                                    {
                                        required: "Este campo es requerido",
                                        pattern: 
                                            {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*?&])[A-Za-z\d$@$!_%*?&]{8,15}[^'\s]/,
                                                message: "La contraseña no cumple con los requisitos"
                                            },
                                        minLength: 
                                            {
                                                value: 8, 
                                                message: 'Mínimo 8 carácteres!'
                                            }
                                    }
                                )
                            }
                        />
                        <button className="btn btn-secondary" type="button" onClick={(Visualizar==='password')?()=>setVisualizar('text'):()=>setVisualizar('password')} id="button-addon2"><Icon.Eye size={16}/></button>
                    </div>
                    <p className="text-danger fw-danger">{!!errors.passwordUno && errors.passwordUno.message}</p>
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        type={Visualizar}
                        placeholder="Repetir contraseña"
                        className={
                            clsx(
                                'form-control form-control-solid h-auto py-5 px-5',
                                {
                                    'is-valid': validInput
                                },
                                {
                                    'is-invalid': errors.passwordDos && errors.passwordDos.type === "required"
                                }
                            )
                        }
                        {
                            ...register('passwordDos',
                                {
                                    required: "Este campo es requerido",
                                    pattern: 
                                        {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*?&])[A-Za-z\d$@$!_%*?&]{8,15}[^'\s]/,
                                            message: "La contraseña no cumple con los requisitos"
                                        },
                                    minLength: 
                                        {
                                            value: 8, 
                                            message: 'Mínimo 8 carácteres!'
                                        }
                                }
                            )
                        }
                    />
                    <p className="text-danger fw-danger">{!!errors.passwordDos && errors.passwordDos.message}</p>
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        id="kt_login_forgot_submit"
                        type="submit"
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    // disabled={formik.isSubmitting}
                    >
                        Enviar
                        {loading && 
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Procesando...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        }
                    </button>
                    <Link to="/auth">
                        <button
                            type="button"
                            id="kt_login_forgot_cancel"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                            Volver
                        </button>
                    </Link>
                </div>
            </Form>
            <ToastContainer />
        </div>
    )
}

export default ResetPassword