/* eslint-disable no-tabs */
import { createContext, useEffect, useState } from 'react'
import { PostRoute } from '../../services/Private'
import { setBuildings } from '../../data/actions/empresa';
import { useDispatch } from 'react-redux';

export const UserContext = createContext()

export const UserProvider = ({children}) => {
    const dispatch = useDispatch();
    const [config, setConfig] = useState([]),
          [userAuth, setUserAuth] = useState([]),
          [roles, setRoles] = useState([]),
          [logo, setLogo] = useState(''),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          publicR = [
            "/auth/login",
            "/auth/forgot-password",
            "/auth/reset-password",
            "/planes"
          ]
          
    useEffect(
        () => {
            setUserAuth((localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')) : null)
            setConfig((localStorage.getItem('config')) ? JSON.parse(localStorage.getItem('config')) : null)
        }, []
    )
   
    useEffect(
        () => {
            // const path = window.location.pathname
            const url = window.location.pathname.split('/')
            if (!userAuth && url[1] !== 'auth' ) {
                localStorage.removeItem('authUser')
                localStorage.removeItem('config')
                window.location.replace("/auth")
            }
        }, [userAuth, publicR]
    )
    useEffect( () => {
            
            if (!config && (userAuth && userAuth.id)) {
                getConfigEmp()
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [userAuth]
    )

    const one = async () => {
        const response = await PostRoute(`empresa/one/empresa`)
        dispatch(setBuildings(response[0] ? response[0] : []))
    }

    useEffect(
        () => {
            if (userAuth && userAuth.length !== 0) {
                setLogo(`data:image/png;base64,${userAuth.foto}`)
                one()
                setRoles(JSON.parse(userAuth.roles))
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [userAuth]
    )

    window.addEventListener('storage', (e) => {
            if (config.length !== 0 && config.tipo) {
                setConfig(JSON.parse(localStorage.getItem('config')))
            }
        }
    )

    const getConfigEmp = async () =>{
        const response = await PostRoute(`configuracion/one/empresa`)
        setConfig(response[0])
        localStorage.setItem('config', JSON.stringify(response[0]))
    }

    const value = {
        setConfig,
        setUserAuth,
        userAuth,
        config,
        roles,
        logo
    }

    return (
        <UserContext.Provider value={value}>
            { children }
        </UserContext.Provider>
    )
}