import { BUILDINGS } from '../types/index';

const initialState = {
    stateEmpresa: []
};

export const empresaReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUILDINGS:
            return {
                ...state,
                stateEmpresa: action.payload,
              };
        default:
            return state;
    }
};

export default empresaReducer;
