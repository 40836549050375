import { ContentProvider } from './context'
import List from './list'

const Planes = () => {
    return (
        <ContentProvider>
            <List/>
        </ContentProvider>
    )
}

export default Planes